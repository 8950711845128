import {isMobile} from "react-device-detect";
const mobileNumber = "31648979985";
// const whatsappLink = isMobile ? "whatsapp://send?phone=*" + mobileNumber : "https://wa.me/" + mobileNumber;

export default {
  "linkedin": {
    href: "https://www.linkedin.com/in/tomreitsma",
    text: "/in/tomreitsma",
    icon: "linkedin",
    newPage: true
  },

  "mail": {
    href: "mailto:tom@triton-it.nl",
    text: "tom@triton-it.nl",
    icon: "mail outline",
    newPage: false
  },

  "github": {
    href: "https://github.com/tomreitsma",
    text: "github.com/tomreitsma",
    icon: "github",
    newPage: true
  },

  "phone": {
    href: "tel:+31648979985",
    text: "+31 6 48 97 99 85",
    icon: "phone",
    newPage: false
  },

  "whatsapp": {
    href: "whatsapp://send?phone=*" + mobileNumber,
    text: "+31 6 48 97 99 85",
    icon: "whatsapp",
    newPage: true
  },

  "instagram": {
    href: "https://www.instagram.com/tomreitsma",
    text: "instagr.am/tomreitsma",
    icon: "instagram",
    newPage: true
  }
};
