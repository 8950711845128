import React, {Component} from 'react';
import Tom from './assets/img/tom.jpg';

import {Icon, Label} from 'semantic-ui-react'

import './App.css';
import ContactMethods from "./components/contactmethods";
import Specializations from "./components/specializations";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <div className="contents">
            <p>
              <img className="bordered-image" src={Tom}/>
            </p>
            <p className="main-title">Tom Reitsma</p>

            <Specializations />
            <ContactMethods />
          </div>
        </div>
      </div>
    );
  }
}

export default App;
