import React, {Component} from 'react';
import {Icon} from "semantic-ui-react";
import {isMobile} from 'react-device-detect';
import ContactMethodTypes from './contact-method-types';

class ContactMethodButton extends Component {
  render() {
    const {selected, name} = this.props;

    const cmt = ContactMethodTypes[name];

    let targetProps = {};

    // let isMobile = false;

    if (!isMobile && cmt.newPage) {
      targetProps = {
        target: '_blank',
        rel: 'noreferrer noopener'
      };
    }

    const obj = <Icon size="large"
                      onMouseOver={() => this.props.selectContactMethod(name)}
                      onMouseOut={() => !isMobile ? this.props.selectContactMethod(null) : null}
                      circular
                      color={selected === name ? 'blue' : 'black'}
                      inverted name={cmt.icon}/>;

    return (
      <span>
        {!isMobile &&
        <a {...targetProps}
           href={cmt.href}>
          {obj}
        </a>
        }

        {isMobile &&

        <a {...targetProps}
           href={cmt.href}>
          {obj}
        </a>

        }
        </span>
    );
  }
}

export default ContactMethodButton;