let _apiUrl;
let _mediaUrl;

switch (process.env.NODE_ENV) {
  case 'production': {
    // _apiUrl = 'https:/api/';
    // _mediaUrl = '';
    break;
  }
  case 'development':
  default: {

    break;
  }
}

export const DEBUG = process.env.NODE_ENV !== 'production';
