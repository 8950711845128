import React, {Component} from 'react';

class Specializations extends Component {
  AVAILABLE_SPECS = [
    // 'python',
    // 'django',
    'Django rest framework',
    'Docker',
    'Ansible',
    'Linux',
    'Devops',
    'Twisted',
    'JavaScript',
    'React',
    'React Native',
    'Nginx',
    'Gitlab',
    'Jenkins',
    'Gunicorn',
    'Azure Cloud',
    'Amazon Web Services',
    'Apache',
    'MariaDB',
    'MySQL',
    'Memcached',
    'Redis',
    'Ruby',
    'Java',
    'Go',
    'Bash scripting',
    'Jira',
    'Confluence',
    'Agile',
  ];

  constructor(props) {
    super(props);

    this.state = {
      currentSpecIndex: 0,
      currentSpec: this.AVAILABLE_SPECS[0],
      selectedContactMethod: null
    }
  }

  cycleCurrentSpecialization() {
    // const {currentSpecIndex,} = this.state;

    // let newSpecIndex = currentSpecIndex + 1;
    //
    // if (newSpecIndex > this.AVAILABLE_SPECS.length - 1) {
    //   newSpecIndex = 0;
    // }

    this.setState({
      currentSpec: this.AVAILABLE_SPECS[Math.floor(Math.random() * this.AVAILABLE_SPECS.length)],
      // currentSpecIndex: newSpecIndex
    });
  }

  componentDidMount() {
    this.cycleInterval = setInterval(() => this.cycleCurrentSpecialization(), 500)
  }

  componentWillUnmount() {
    clearInterval(this.cycleInterval);
  }

  render() {
    const {currentSpec} = this.state;

    return (
      <p>
        <p className="sub-title">Freelance Senior Software Engineer / Architect specialized in <span
          className="specialization">Python</span>/<span
          className="specialization">Django</span></p>
        <p className="sub-title">
          and... <span className="specialization">{currentSpec}</span>
        </p>
      </p>
    );
  }
}

export default Specializations;