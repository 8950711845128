import React, {Component} from 'react';
import {Icon, Label} from "semantic-ui-react";
import {isMobile} from "react-device-detect";
import ContactMethodButton from "./contact-method-button";

import ContactMethodTypes from './contact-method-types';

class ContactMethods extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedContactMethod: null
    };

    this.selectContactMethod = this.selectContactMethod.bind(this);
  }

  selectContactMethod(target) {
    this.setState({selectedContactMethod: target});
  }

  render() {
    const {selectedContactMethod} = this.state;

    let contactMethod = (
      <p>Nothing</p>
    );

    const cmt = ContactMethodTypes[selectedContactMethod];

    let targetProps = null;
    if (isMobile && cmt && cmt.newPage) {
      targetProps = {
        target: '_blank',
        rel: 'noreferrer noopener'
      };
    }

    return (
      <div>
        <p>
          {/*<Icon size="large" circular inverted name='facebook f'/>*/}

          <ContactMethodButton
            name="linkedin"
            selectContactMethod={this.selectContactMethod}
            selected={selectedContactMethod}/>

          <ContactMethodButton
            name="mail"
            selectContactMethod={this.selectContactMethod}
            selected={selectedContactMethod}/>

          <ContactMethodButton
            name="github"
            selectContactMethod={this.selectContactMethod}
            selected={selectedContactMethod}/>

          <ContactMethodButton
            name="phone"
            selectContactMethod={this.selectContactMethod}
            selected={selectedContactMethod}/>

          <ContactMethodButton
            name="whatsapp"
            selectContactMethod={this.selectContactMethod}
            selected={selectedContactMethod}/>

          <ContactMethodButton
            name="instagram"
            selectContactMethod={this.selectContactMethod}
            selected={selectedContactMethod}/>
        </p>
        <p>
          {selectedContactMethod &&
          <Label color={'black'}>
            <a {...targetProps} href={cmt.href}>
              {cmt.text}
            </a>
          </Label>
          }
        </p>
      </div>
    );
  }
}

export default ContactMethods;